import { useState } from 'react';
import { Alert, Loading } from '@arcadiapower/shrike';
import { z } from 'zod';
import { trpc } from '@client/utils/trpc';
import { useTranslation } from 'react-i18next';
import { Callbacks, UseCase, apiModes, usecases } from '@schema/schemas';
import { ContentSection } from './components/connect-container';
import { ConnectScreens } from './machines/connect/connect-screens';
import { postToParentCallback } from './config/communicators';

const UpdateParamsSchema = z.object({
  updateToken: z.string(),
});

type UpdateParams = z.infer<typeof UpdateParamsSchema>;

const RefreshParamsSchema = z.object({
  refreshToken: z.string(),
});

type RefreshParams = z.infer<typeof RefreshParamsSchema>;

const CreateParamsSchema = z.object({
  providerId: z.string().nullish(),
  correlationId: z.string().nullish(),
  apiMode: apiModes.nullish(),
  useCaseLimit: usecases.nullish(),
});

type CreateParams = z.infer<typeof CreateParamsSchema>;

const ParamsSchema = z.union([UpdateParamsSchema, RefreshParamsSchema, CreateParamsSchema]);

export const StandaloneWrapper = (): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'standaloneWrapper' });

  const url = new URL(window.location.toString());
  const path = url.pathname;
  const encodedUrlParams = path.replace('/', '');
  let uniqueId: string = '';
  if (encodedUrlParams.length === 20) uniqueId = encodedUrlParams;
  const [callbacks] = useState(() => setUpCallbacks());

  const params = Object.fromEntries(url.searchParams);
  const parsedParams = ParamsSchema.parse(params);

  const authQueries = trpc.useQueries(t => [
    t.auth.verifyUpdateToken((parsedParams as UpdateParams).updateToken, {
      enabled: !!(parsedParams as UpdateParams).updateToken,
    }),
    t.auth.verifyRefreshToken((parsedParams as RefreshParams).refreshToken, {
      enabled: !!(parsedParams as RefreshParams).refreshToken,
    }),
    t.auth.verifyEncodedUrl(encodedUrlParams.replace('/', ''), {
      enabled: uniqueId.length === 0 && !!encodedUrlParams,
    }),
  ]);

  const [{ data: updateTokenData }, { data: refreshTokenData }, { data: decodedUrl }] = authQueries;

  const providerId =
    'providerId' in parsedParams
      ? parsedParams.providerId
      : updateTokenData?.providerId ?? refreshTokenData?.providerId;
  const orgId = uniqueId.length === 0 ? decodedUrl?.orgId ?? '' : uniqueId;
  const { correlationId, apiMode, useCaseLimit: useCaseLimitParam } = parsedParams as CreateParams;
  const queries = trpc.useQueries(t => [
    t.organization.getByUniqueId(orgId!, { enabled: !!orgId }),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    t.provider.getById(
      { providerId: providerId!, apiMode: apiMode ?? 'live' },
      { enabled: !!providerId }
    ),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    t.provider.getAcquisitionTemplate({ providerId: providerId! }, { enabled: !!providerId }),
  ]);

  const allQueries = [...authQueries, ...queries];

  const [{ data: orgData }, { data: providerData }, { data: acquisitionTemplateData }] = queries;

  if (allQueries.some(q => q.isInitialLoading)) {
    return (
      <ContentSection centerContentVertically centerContentHorizontally>
        <Loading />
      </ContentSection>
    );
  }

  if (!uniqueId && !decodedUrl?.orgId) {
    // TODO: do something better here
    return (
      <ContentSection centerContentVertically centerContentHorizontally>
        <Alert>{t('errors.noOrgId')}</Alert>
      </ContentSection>
    );
  }

  if (!orgData || allQueries.some(q => q.isError)) {
    // TODO: parse error
    return (
      <ContentSection centerContentVertically centerContentHorizontally>
        <Alert>{t('errors.generic')}</Alert>
      </ContentSection>
    );
  }

  if (
    !!useCaseLimitParam &&
    ((useCaseLimitParam === 'intervals' && orgData.isStatementsOnly) ||
      (useCaseLimitParam === 'statements' && orgData.isIntervalsOnly))
  ) {
    return (
      <ContentSection centerContentVertically centerContentHorizontally>
        <Alert>{t('errors.invalidUsecase')}</Alert>
      </ContentSection>
    );
  }
  // if they set the usecase, it only makes sense to use it if they have both statements and intervals enabled
  // for intervals only orgs, we have to limit them to intervals, and vice versa for statements
  let useCaseLimit: UseCase | undefined;
  if (orgData.isStatementsAndIntervals && !!useCaseLimitParam) {
    useCaseLimit = useCaseLimitParam;
  } else if (orgData.isIntervalsOnly) {
    useCaseLimit = 'intervals';
  } else if (orgData.isStatementsOnly) {
    useCaseLimit = 'statements';
  }

  return (
    <ConnectScreens
      organization={orgData}
      config={{
        providerDetails:
          providerData && acquisitionTemplateData
            ? {
                provider: providerData,
                acquisitionTemplate: acquisitionTemplateData,
              }
            : undefined,
        correlationId: decodedUrl?.correlationId
          ? decodedUrl?.correlationId
          : correlationId || undefined,
        createdBy: decodedUrl?.createdBy,
        allowSettingCorId: !!decodedUrl?.createdBy,
        updateTokenInfo: updateTokenData,
        refreshTokenInfo: refreshTokenData,
        apiMode: apiMode || 'live',
        callbacks,
        useCaseLimit,
      }}
    />
  );
};

// Exported for testing
export const setUpCallbacks = (): Callbacks | undefined => {
  const isIframe = window.top !== window.self;
  if (!isIframe) return undefined;

  return {
    onStart: postToParentCallback('onStart'),
    onEnd: postToParentCallback('onEnd'),
    onCredentialsSubmitted: postToParentCallback('onCredentialsSubmitted'),
  };
};
