import { useState, useMemo, useCallback } from 'react';
import { Button, FlexCrate, Loading, Text } from '@arcadiapower/shrike';
import { ApiMode, Organization, Provider, UseCase } from '@arc-connect/schema';
import { trpc } from '@client/utils/trpc';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from '@client/components/form';
import SVG from 'react-inlinesvg';
import arcadiaBadge from 'packages/client/src/assets/icons/arcadia-badge.svg';
import { DebouncedSearchBar } from '@client/components/debounced-search-bar';
import { ContentSectionFullHeight, Footer } from '@client/components/connect-container';
import { parseErrorMessage, UserError } from '@client/config/errors';
import { trackEvent, TrackEvents } from '@client/utils/analytics';
import {
  BadgeWrapper,
  ProviderContentWrapper,
  ProviderOption,
  ProviderOptionWrapper,
  StyledSpan,
} from './provider-form.style';
import { ProviderHeader } from './provider-header';

export type Props = {
  submissionError?: unknown;
  pending: boolean;
  initialProviderSearch?: string;
  organization: Organization;
  onSubmit: ({
    provider,
    providerSearchString,
  }: {
    provider: Provider;
    providerSearchString: string;
  }) => void;
  apiMode?: ApiMode;
  usecasesLimit?: UseCase;
};

const searchStringValid = (searchString: string) => searchString.length >= 3;

export const ProviderForm = ({
  submissionError,
  pending,
  onSubmit,
  initialProviderSearch,
  organization,
  apiMode = 'live',
  usecasesLimit,
}: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.providerForm' });
  const [provider, setProvider] = useState<Provider | undefined>();
  const [noProviderError, setNoProviderError] = useState<UserError | undefined>();
  const [providerSearchString, setProviderSearchString] = useState(
    () => initialProviderSearch ?? ''
  );

  const handleSubmit = () => {
    if (provider && providerSearchString) {
      trackEvent(TrackEvents.PROVIDER_SELECTED, {
        providerId: provider.id,
        providerName: provider.name,
      });
      onSubmit({ provider, providerSearchString });
    }
  };

  const handleClick = () => {
    if (!provider) setNoProviderError(new UserError('noProviderError'));
    else handleSubmit();
  };

  const canSearch = searchStringValid(providerSearchString);

  const { data, isLoading, error } = trpc.provider.search.useQuery(
    { name: providerSearchString, apiMode, usecasesLimit },
    {
      enabled: canSearch,
    }
  );

  const handleSearch = useCallback((searchString: string) => {
    setProviderSearchString(searchString);
    trackEvent(TrackEvents.PROVIDER_SEARCHED, { searchString });
    setProvider(undefined);
    setNoProviderError(undefined);
  }, []);

  const providers = useMemo(() => {
    return data?.providers;
  }, [data]);

  const handleProviderSelection = useCallback(
    (providerId: string) => {
      if (providers) {
        const selectedProvider = providers?.find(
          (provider: Provider) => provider.id === providerId
        );
        setProvider(selectedProvider);
      }
    },
    [providers]
  );

  // TO DO: update the parseErrorMessage function to actually respond to these errors
  // right now it only works for noProviderError
  const providerError = noProviderError || submissionError || error;
  const errorMessage = providerError instanceof Error ? parseErrorMessage(providerError) : '';

  return (
    <>
      <ContentSectionFullHeight withTopPadding={false}>
        <ProviderHeader organization={organization} />
        <DebouncedSearchBar
          initialSearchInput={initialProviderSearch}
          label={t('searchLabel')}
          onSearch={handleSearch}
          name="Provider search"
          errorText={errorMessage}
          autoFocus={true}
        />
        <ProviderContentWrapper
          errorPresent={!!(providerError instanceof Error)}
          providersPresent={!!providers?.length}
        >
          {providers ? (
            <Form onSubmit={handleSubmit} id="providerForm">
              {providers.map((providerOption: Provider) => (
                <ProviderOptionWrapper key={providerOption.id}>
                  <ProviderOption
                    value={providerOption.id}
                    name="provider"
                    onChange={handleProviderSelection}
                    checked={providerOption.id === provider?.id}
                  >
                    {providerOption.name}
                  </ProviderOption>
                </ProviderOptionWrapper>
              ))}
            </Form>
          ) : (
            <div>{canSearch && isLoading ? <Loading /> : <Text>{t('searchHelpText')}</Text>}</div>
          )}
          {!!(canSearch && !providers?.length && !isLoading) && (
            <FlexCrate flexDirection="column" alignItems="center">
              <Text>
                <Trans t={t} i18nKey="noProvidersText">
                  <StyledSpan>{{ providerSearchString } as any}</StyledSpan>
                </Trans>
              </Text>
              <Text>{t('refineSearchText')}</Text>
            </FlexCrate>
          )}
        </ProviderContentWrapper>
        <BadgeWrapper>
          <SVG src={arcadiaBadge} />
        </BadgeWrapper>
      </ContentSectionFullHeight>
      <Footer>
        <Button
          form="providerForm"
          aria-label={t('continueButton')}
          type="submit"
          loading={pending}
          fullWidth={true}
          onClick={handleClick}
          mode="primary"
        >
          {t('continueButton')}
        </Button>
      </Footer>
    </>
  );
};
